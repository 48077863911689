import { css } from '@emotion/react'
import { Container, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import type { SessionEvent } from '@orus.eu/backend/src/events/session-event'
import type { SubscriptionInfo } from '@orus.eu/backend/src/routers/sessions'
import { nbsp } from '@orus.eu/char'
import { ButtonLink, spacing, Text } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, type FunctionComponent } from 'react'
import UAParser from 'ua-parser-js'
import { trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { formatDateTime } from '../../../../lib/format'
import { useBuildUrl } from '../../../../use-build-url'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'
import { BackofficeSubsectionTitle } from '../../../atoms/backoffice-subsection-title'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { TableCellDateTime } from '../../../molecules/table-elements/table-cell-datetime'
import { TableCellHeader } from '../../../molecules/table-elements/table-cell-header'
import { TableCellText } from '../../../molecules/table-elements/table-cell-text'

const PlatformTroubleshootingSessionPage: FunctionComponent = memo(function PlatformTroubleshootingSessionPage() {
  const { sessionId } = useParams({ from: '/bak/troubleshooting/session/$sessionId' })
  const { buildUrl } = useBuildUrl()
  assert(sessionId, 'This page required a inputSetId param')
  const sessionInfo = trpcReact.sessions.getSessionTroubleshootingInfo.useQuery(sessionId).data

  if (!sessionInfo) {
    return <GlobalLoadingState />
  }

  const { user, subscriptions, sessionEvents, isUserAnOrusAgent, isUserPartnerMember } = sessionInfo

  return (
    <>
      <Container maxWidth="sm">
        <BackofficeSectionTitle>Détails de la session</BackofficeSectionTitle>

        {user ? (
          <>
            <BackofficeSubsectionTitle>Client</BackofficeSubsectionTitle>
            {(isUserAnOrusAgent || isUserPartnerMember) && 'email' in user ? (
              <Text>{user.email}</Text>
            ) : (
              <LinkedObjectButton
                href={buildUrl({ to: '/bak/users/$userId', params: { userId: user.id } })}
                text="Voir l'utilisateur propriétaire de la session"
              />
            )}
          </>
        ) : (
          <></>
        )}

        <BackofficeSubsectionTitle>Devis</BackofficeSubsectionTitle>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: ${spacing[50]};
          `}
        >
          {subscriptions.map((subscription) => (
            <LinkedObjectButton
              key={subscription.quoteInputSetId}
              href={buildUrl({
                to: '/bak/v2-pending-subscriptions/$subscriptionId',
                params: { subscriptionId: subscription.quoteInputSetId },
              })}
              text={getSubscriptionLabel(subscription)}
            />
          ))}
        </div>

        <BackofficeSubsectionTitle>Evenements de la session</BackofficeSubsectionTitle>

        <TableContainer>
          <TableHead>
            <TableCellHeader>Création</TableCellHeader>
            <TableCellHeader>Systeme</TableCellHeader>
            <TableCellHeader>Navigateur</TableCellHeader>
            <TableCellHeader>Ecran</TableCellHeader>
          </TableHead>
          <TableBody>
            {sessionEvents.map((sessionEvent) => (
              <TableRow key={sessionEvent.id}>
                <TableCellDateTime dateTime={sessionEvent.timestamp} />
                <TableCellText text={getOsLabel(sessionEvent.userAgent)} />
                <TableCellText text={getBrowserLabel(sessionEvent.userAgent)} />
                <TableCellText text={getScreenLabel(sessionEvent.screen)} />
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>

        <BackofficeSubsectionTitle>Données brutes</BackofficeSubsectionTitle>
        <div
          css={css`
            margin-top: ${spacing[70]};
          `}
        >
          <pre>{JSON.stringify(sessionInfo, null, 4)}</pre>
        </div>
      </Container>
    </>
  )
})

export default PlatformTroubleshootingSessionPage

const LinkedObjectButton = memo<{ text: string; href: string }>(function LinkedObjectButton({ text, href }) {
  return (
    <ButtonLink
      size="small"
      variant="secondary"
      to={href}
      icon="arrow-up-right-from-square-regular"
      avatarPosition="right"
      fullWidth={false}
    >
      {text}
    </ButtonLink>
  )
})

function getOsLabel(userAgentString: string | null | undefined): string {
  if (!userAgentString) return '<inconnu>'
  const uaParser = new UAParser(userAgentString)
  const userAgent = uaParser.getResult()
  return [userAgent.os.name, userAgent.os.version].filter((s) => !!s).join(' ')
}

function getBrowserLabel(userAgentString: string | null | undefined): string {
  if (!userAgentString) return '<inconnu>'
  const uaParser = new UAParser(userAgentString)
  const userAgent = uaParser.getResult()
  return [
    userAgent.browser?.name,
    userAgent.browser?.version,
    userAgent.device.model,
    userAgent.device.type,
    userAgent.device.vendor,
  ]
    .filter((s) => !!s)
    .join(' ')
}

function getScreenLabel(screen: SessionEvent['screen'] | undefined): string {
  if (!screen) return '<inconnu>'
  return [screen.width, 'x', screen.height, 'x', `${screen.density}²`].join(nbsp)
}

function getSubscriptionLabel(subscription: SubscriptionInfo) {
  return `${formatDateTime(subscription.creationTimestamp)} - ${subscription.operatorEmail ?? 'prospect'}`
}
